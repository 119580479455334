import dayjs from 'dayjs';
import lengthConverter from 'utils/length-converter';
import timeInterval from 'utils/time-interval';

export class OpenHouse {
  to: string | null;
  from: string | null;
  date: string | null;

  constructor(openHouse: Record<string, unknown>) {
    Object.assign(this, openHouse);
  }

  get isActive() {
    if (this.date) {
      const date = new Date(this.date);
      const hoursMatch = this.to?.match(/^(\d+):/);
      if (hoursMatch) {
        date.setHours(parseInt(hoursMatch[1], 10) + (this.to?.match(/pm/i) ? 12 : 0));
      }
      const minutesMatch = this.to?.match(/:(\d+)/);
      if (minutesMatch) {
        date.setMinutes(parseInt(minutesMatch[1], 10));
      }
      return date > new Date();
    } 
    return false;
  }

  get label() {
    const timez = new Date().toLocaleTimeString('en-us', { timeZoneName:'short' }).split(' ')[2];
    const targetTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let convertedFromDateStr: string | null = null;
    let convertedToDateStr: string | null = null;

    if (this.from){
      const fromDate = createDate(this.date, this.from);
      convertedFromDateStr = convertToTimeZone(fromDate, targetTimeZone);
    }

    if (this.to){
      const toDate = createDate(this.date, this.to);
      convertedToDateStr = convertToTimeZone(toDate, targetTimeZone);
    }

    let label = dayjs(this.date).format('ddd. MMM D');
    if (this.from) {
      label += ', ' + timeInterval(convertedFromDateStr || '', convertedToDateStr || undefined) + ' ' + timez;
    }
    return label;
  }
}

export class Room {
  type: string | null;
  level: string | null;
  width: number | null;
  length: number | string | null;
  unit: string | null;

  constructor(room: Record<string, unknown>) {
    Object.assign(this, room);
  }

  size = (desiredUnit: string) => {
    const { unit, width, length } = this;
    if (width && length && unit) {
      return `${lengthConverter(width, unit, desiredUnit)} × ${lengthConverter(Number(length), unit, desiredUnit)} ${desiredUnit === 'feet' ? 'ft' : 'm'}`;
    } else {
      return 'Not available';
    }
  };
}

//Input open house times are in America/New_York timezone: {"to":"12:00PM","date":"2024-12-31","from":"11:00AM"}
function createDate(date, time) {
  // Parse the time (HH:MM AM/PM) and split into components
  const timeMatch = time.match(/^(\d+):(\d+)(AM|PM)$/);
  if (!timeMatch) {
    throw new Error('Invalid time format. Expected format: HH:MMAM/PM.');
  }

  const [_, hours, minutes, period] = timeMatch;

  // Convert to 24-hour format
  let hour = parseInt(hours, 10);
  if (period === 'PM' && hour !== 12) {
    hour += 12;
  } else if (period === 'AM' && hour === 12) {
    hour = 0;
  }

  // Create a Date object wiht offset, since with the input coming in America/New_York time
  const nyDate = new Date(`${date}T${String(hour).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:00-05:00`); // EST Offset (-05:00)

  // Convert to UTC
  const utcDate = new Date(nyDate.getTime());
  return utcDate;
}

function convertToTimeZone(date: Date, timeZone: string): string {
  const options: Intl.DateTimeFormatOptions = {
    timeZone: timeZone,
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: true,
  };
  return new Intl.DateTimeFormat('en-US', options).format(date);
}
