import type { IncomingHttpHeaders } from 'http';

// Source: https://stackoverflow.com/a/60911528
/* cSpell:disable */
const CRAWLER_AGENTS = [
  'googlebot', 'yandexbot', 'yahoo', 'bingbot',
  'baiduspider', 'facebookexternalhit', 'twitterbot', 'rogerbot',
  'linkedinbot', 'embedly', 'quora link preview', 'showyoubot', 'outbrain',
  'pinterest/0.', 'developers.google.com/+/web/snippet',
  'slackbot', 'vkshare', 'w3c_validator', 'redditbot', 'applebot',
  'whatsapp', 'flipboard', 'tumblr', 'bitlybot', 'skypeuripreview',
  'nuzzel', 'discordbot', 'google page speed', 'headlesschrome',
  'googleother', 'ahrefsbot', 'blexbot', 'semrushbot', 'meta-externalagent',
  'barkrowler', 'bytespider', 'amazonbot', 'appsignalbot', 'imagesiftbot', 'adsbot',
];

export default function isUserAgentCrawler(initialUserAgent: IncomingHttpHeaders['user-agent']) {
  const userAgent = (initialUserAgent || '').toLowerCase();
  const isCrawler = CRAWLER_AGENTS.some(crawlerAgent => userAgent.indexOf(crawlerAgent) !== -1);
  return isCrawler;
}

export function isUserAgentLighthouse(initialUserAgent: IncomingHttpHeaders['user-agent']) {
  const userAgent = (initialUserAgent || '').toLowerCase();
  const isLighthouse = /moto g|chrome-lighthouse/.test(userAgent);
  return isLighthouse;
}